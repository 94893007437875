import { useRef, useEffect } from 'preact/hooks';
import Modal from "./Modal";

type ViewMode = 'half' | 'tall' | 'full';

interface WebviewProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  url: string;
  viewMode: ViewMode;
}

// Function to inject CSS into a <style> tag
const injectCSS = (cssContent: string) => {
  const style = document.createElement('style');
  style.textContent = cssContent;
  document.head.appendChild(style);
};

const Webview: preact.FunctionalComponent<WebviewProps> = ({
 isOpen,
 hasCloseBtn = true,
 onClose,
 url,
 viewMode
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const cssContent = `
      .botman-widget-webview {
        border: 0;
        display: block;
      }
      
      .botman-widget-webview.half {
        height: 75vh;
        width: 50vw;
      }
      
      .botman-widget-webview.tall {
        height: 85vh;
        width: 65vw;
      }
      
      .botman-widget-webview.full {
        height: 95vh;
        width: 95vw;
      }
      
      @media only screen and (max-width: 1024px) {
        .botman-widget-webview.half {
          width: 95vw;
        }
      
        .botman-widget-webview.tall {
          width: 95vw;
        }
      }
    `;
    injectCSS(cssContent);
  }, []);

  return (
    <Modal
      hasCloseBtn={hasCloseBtn}
      isOpen={isOpen}
      onClose={onClose}>
      <iframe
        ref={iframeRef}
        className={`botman-widget-webview ${viewMode}`}
        src={url} />
    </Modal>
  );
};

export default Webview;
export type { ViewMode };
