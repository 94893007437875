import { useRef, useEffect, useState } from 'preact/hooks';

interface ModalProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children: preact.ComponentChildren;
}

// Function to inject CSS into a <style> tag
const injectCSS = (cssContent: string) => {
  const style = document.createElement('style');
  style.textContent = cssContent;
  document.head.appendChild(style);
};

const Modal: preact.FunctionalComponent<ModalProps> = ({
 isOpen,
 hasCloseBtn = true,
 onClose,
 children
}) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const cssContent = `
      .botman-widget-modal {
        padding: 0;
        border: 0;
        border-radius: 0.5rem;
        box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
        margin: auto;
        max-height: 100vh;
        max-width: 100vw;
        position: fixed;
        z-index: 10000000000000;
      }
      
      .botman-widget-modal::backdrop {
        background: hsl(0 0% 0% / 50%);
      }
      
      .botman-widget-modal-close-btn {
        background-color: #333;
        border: none;
        border-radius: 5px;
        color: #fff;
        display: block;
        font-size: 20px;
        height: 30px;
        outline: none;
        padding: 0;
        position: absolute;
        text-align: center;
        right: 15px;
        top: 15px;
        width: 30px;
      }
  `;
    injectCSS(cssContent);
  }, []);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }

      // Attach keydown event listener
      modalElement.addEventListener('keydown', handleKeyDown);

      // Cleanup event listener on unmount
      return () => modalElement.removeEventListener('keydown', handleKeyDown);
    }
  }, [isModalOpen]);

  return (
    <dialog ref={modalRef} className="botman-widget-modal">
      {hasCloseBtn && (
        <button className="botman-widget-modal-close-btn" onClick={handleCloseModal}>✖&#xFE0E;</button>
      )}
      {children}
    </dialog>
  );
};

export default Modal;
